import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core'
import { XpenserTheme } from '@styles/theme'

import {
  AUTH_VERIFY_URL,
  AUTH_LOGIN_URL
} from '@store/constants'

import { Provider as AuthenticationProvider } from '@components/auth'
import PrivateRoute from '@components/private-route'
import AuthCallback from '@components/auth-callback'
import Login from '@components/auth-login'
import Logout from '@components/auth-logout'

import {
  HomePage,
  DashboardPage,
  PageNotFound
} from '@pages/index'

import '@styles/global.scss'

function App() {
  return (
    <React.StrictMode>
      <ThemeProvider theme={XpenserTheme}>
        <div className='App'>
          <AuthenticationProvider verifyUrl={AUTH_VERIFY_URL} key='authentication'>
            <BrowserRouter>
              <Switch>
                {/* Open/Public Routes */}
                <Route exact path='/' component={HomePage} />
                <Route exact path='/auth/callback' component={AuthCallback} />
                <Route exact path='/logout' render={() => <Logout />} />
                <Route exact path='/login' render={() => <Login url={AUTH_LOGIN_URL} />} />
                
                {/* Private/Authenticated Routes */}
                <PrivateRoute path='/dashboard' component={DashboardPage} />

                {/* Catch Alls/Error pages */}
                <Route component={PageNotFound} />
              </Switch>
            </BrowserRouter>
          </AuthenticationProvider>
        </div>
      </ThemeProvider>
    </React.StrictMode>
  )
}

export default App
